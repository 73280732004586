@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Ruda&display=swap');


body {
    font-family: 'Oswald', sans-serif;
    font-family: 'Ruda', sans-serif;
}

.App {
    font-family: 'Oswald', sans-serif;
    font-family: 'Ruda', sans-serif;
}

