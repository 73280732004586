

body, div {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  font-family: 'Ruda', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #3C4142; */
}

code {
  font-family: 'Oswald', sans-serif;
  font-family: 'Ruda', sans-serif;
}
